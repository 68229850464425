import React, { CSSProperties } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SiteNavLogo, SiteNavLogoWhite } from './SiteNavLogo';

interface SiteNavProps {
  isPost?: boolean;
  whiteLogo?: boolean;
  post?: any;
}

interface SiteNavState {
  showTitle: boolean;
}

export const outer = css`
  position: relative;
  padding: 0 5vw;
`;

// Centered content container blocks
export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

const SiteNavMain = css`
  background: #fff;

  transition: background .5s, color .5s;

  &.isSticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
  }

  &.transparentHeader {
    background: transparent;
    box-shadow: none;

    li a {
      color: #fff;
    }

    li a:before {
      background: #fff;
    }

    .ActionButton {
      background: #fff;
      color: #000;
    }
  }
`;

// tag and author post lists
export const SiteArchiveHeader = css`
  .site-header-content {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
  }
`;

const ActionButton = css`
  && {
    background: #2c76bc;
    color: #fff;
    border-radius: 4px;
    padding: 6px 12px;
    margin-top: 6px;
    margin-left: 19px;
    transition: .1s background;

    :before {
      display: none;
    }
  
    :hover, :focus {
      background: #296dae;
      color: #fff;
    }
  
    :active {
      background: #2665a0;
      color: #fff;
    }
  }
`;

class SiteNav extends React.Component<SiteNavProps, SiteNavState> {
  titleRef = React.createRef<HTMLSpanElement>();
  lastScrollY = 0;
  ticking = false;
  state = { showTitle: false };

  componentDidMount(): void {
    this.lastScrollY = window.scrollY;
    if (this.props.isPost) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    if (!this.ticking) {
      requestAnimationFrame(this.update);
    }

    this.ticking = true;
  };

  update = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    this.lastScrollY = window.scrollY;

    const trigger = this.titleRef.current.getBoundingClientRect().top;
    const triggerOffset = this.titleRef.current.offsetHeight + 35;

    // show/hide post title
    if (this.lastScrollY >= trigger + triggerOffset) {
      this.setState({ showTitle: true });
    } else {
      this.setState({ showTitle: false });
    }

    this.ticking = false;
  };

  render() {
    const { isPost = false, post = {}, whiteLogo } = this.props;

    return (
      <nav css={SiteNavStyles}>
        <SiteNavLeft className="site-nav-left">
          {whiteLogo ? <SiteNavLogoWhite /> : <SiteNavLogo />}
          <SiteNavContent css={[this.state.showTitle ? HideNav : '']}>
            <ul css={NavStyles} role="menu">
              {/* TODO: mark current nav item - add class nav-current */}
              <li role="menuitem">
                <Link to="/integrations">Integrations</Link>
              </li>
              {/*
              <li role="menuitem">
                <Link to="/examples">Examples</Link>
              </li>
              */}
              <li role="menuitem">
                <Link to="/pricing">Pricing</Link>
              </li>
              <li role="menuitem">
                <a href="https://docs.fastapps.io/">Docs</a>
              </li>
              <li role="menuitem">
                <Link to="/blog">Blog</Link>
              </li>
              <li role="menuitem">
                <a href="http://app.fastapps.io/user/login">
                  Sign In
                </a>
              </li>
              <li role="menuitem">
                <a css={ActionButton} className="ActionButton" href="http://app.fastapps.io/user/signup">
                  Try for Free
                </a>
              </li>
            </ul>
            {isPost && (
              <NavPostTitle ref={this.titleRef} className="nav-post-title">
                {post.title}
              </NavPostTitle>
            )}
          </SiteNavContent>
        </SiteNavLeft>
        <SiteNavRight>
          <SocialLinks>
            {' '}
          </SocialLinks>
        </SiteNavRight>
      </nav>
    );
  }
}

const SiteNavStyles = css`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 70px;
  font-size: 1.3rem;
`;

const SiteNavLeft = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding: 10px 0 80px;
  font-weight: 500;
  letter-spacing: 0.2px;
  white-space: nowrap;
  justify-content: space-between;

  -ms-overflow-scrolling: touch;

  @media (max-width: 700px) {
    margin-right: 0;
    padding-left: 5vw;
  }
`;

const SiteNavContent = styled.div`
  position: relative;
  align-self: flex-start;
`;

const NavStyles = css`
  display: flex;
  margin: 6px 0 0 -12px;
  padding: 0;
  list-style: none;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  li a {
    position: relative;
    display: block;
    padding: 12px 12px;
    color: #000;
    opacity: 0.8;
    transition: opacity 0.35s ease-in-out;
  }

  li a:hover {
    text-decoration: none;
    opacity: 1;
  }

  li a:before {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 8px;
    left: 12px;
    height: 1px;
    background: #000;
    opacity: 0.25;
    transition: all 0.35s ease-in-out;
  }

  li a:hover:before {
    right: 12px;
    opacity: 0.5;
  }
`;

const SiteNavRight = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  height: 64px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const NavPostTitle = styled.span`
  visibility: hidden;
  position: absolute;
  top: 9px;
  color: #000;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: none;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(175%);

  .dash {
    left: -25px;
  }

  .dash:before {
    content: '– ';
    opacity: 0.5;
  }
`;

const HideNav = css`
  ul {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-175%);
  }
  .nav-post-title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;




interface ITransitionNavigationProps {
  /**
   * white = transparent background with a white logo
   * @default normal
   */
  mode?: "white" | "normal";

  /**
   * if sticky is set the navigate with stick when scrolling. This will also cause the mode to change to normal with "stuck"
   * @default false
   */
  isSticky?: boolean;

  /**
   * Custom background color (for example #2c76bc)
   * @default undefined
   */
  background?: string;
}

interface ITransitionNavigationState {
  isAtTop: boolean;
}

export class TransitionNavigation extends React.Component<ITransitionNavigationProps, ITransitionNavigationState> {
  static defaultProps = {
    mode: "normal",
    isSticky: false,
    background: undefined
  };

  state = {
    isAtTop: typeof window !== "undefined" ? window.scrollY === 0 : true
  }

  componentDidMount = () => {
    if (this.props.isSticky) {
      this.addScroll();
    }
  }

  componentDidUpdate = (prevProps: ITransitionNavigationProps) => {
    if (prevProps.isSticky !== this.props.isSticky) {
      this.removeScroll();

      if (this.props.isSticky) {
        // sticky changed, add event listener and recompute
        this.addScroll();
      }
    }
  };

  componentWillUnmount = () => {
    this.removeScroll();
  }

  private addScroll = () => {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    // this.handleScroll();
  };

  private removeScroll = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event: Event) => {
    const scrollPosition = (event.target as any).documentElement.scrollTop;
    const isAtTop = scrollPosition === 0;

    if (isAtTop !== this.state.isAtTop) {
      this.setState({
        isAtTop: scrollPosition === 0
      });
    }
  }

  render() {
    let classNames: string = "";

    if (this.props.mode === "white") {
      classNames = this.state.isAtTop ? "transparentHeader" : "";
    }

    if (this.props.isSticky) {
      classNames += " isSticky";
    }

    let customBackground: undefined | CSSProperties = undefined;

    if (this.props.background) {
      customBackground = {
        background: this.props.background,
        marginBottom: -200,
        paddingBottom: 200
      };
    }

    return (
      <header className="site-archive-header no-image" css={SiteArchiveHeader}>
        <div css={[outer, SiteNavMain]} className={classNames} style={customBackground}>
          <div css={inner}>
            <SiteNav whiteLogo={this.state.isAtTop && this.props.mode === "white"} />
          </div>
        </div>
      </header>
    );
  }
}